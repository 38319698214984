export default class CSRF {
  static getToken() {
    try {
      return this.getTag().getAttribute('content');
    } catch (error) {
      return null;
    }
  }

  static getTag() {
    return document.getElementsByName('csrf-token')[0];
  }
}
