import { Controller } from '@hotwired/stimulus'
import ClassicEditor from '@citysupport/ckeditor5-build-classic'
import CSRF from '../services/CSRF'

export default class extends Controller {
  connect() {
    ClassicEditor.create(this.element, {
      toolbar: {
        items: [
          'heading', '|',
          'bold', 'italic', 'underline', 'link', 'bulletedList', 'numberedList', '|',
          'insertTable', 'tableRow', 'tableColumn', 'mergeTableCells', '|',
          'uploadImage', '|',
          'undo', 'redo'
        ]
      },
      image: {
        toolbar: [
          'imageStyle:full',
          'imageStyle:side',
          '|',
          'imageTextAlternative'
        ],
      },
      simpleUpload: {
        uploadUrl: '/admin/images',
        headers: {
          'X-CSRF-TOKEN': CSRF.getToken(),
        },
      },
      title: {
        placeholder: false
      },
      link: {
        decorators: [
          {
            mode: 'manual',
            label: 'Open in a new tab',
            defaultValue: true,
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          }
        ]
      },
      language: 'en',
    });
  }
}
