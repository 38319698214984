import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['previous', 'next']

  connect() {
    document.addEventListener('keydown', (event) => {
      switch(event.key) {
        case 'ArrowLeft':
          event.preventDefault();
          if (this.hasPreviousTarget) {
            this.previousTarget.click();
          }
          break;
        case 'ArrowRight':
          event.preventDefault();
          if (this.hasNextTarget) {
            this.nextTarget.click();
          }
          break;
      };
    });
  }
}
